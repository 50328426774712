<template>
<div class="LayoutHolder" id="PageTop">
	<section class="WhiteSmoke">
		<div class="Container">
			<div class="Box ProductListHead">
				<div class="HeadingGroup">
					<h1>ABBIGLIAMENTO</h1>
					<div class="TxtEditor">
					</div>
				</div>
				<!-- <div class="ItemThumbLayout">
					<div class="ItemThumbLayoutSwitch Grid"><button onClick="grid();"><img src="/images/GridViewIcon.png" srcset="/images/GridViewIconRetina.png 2x" alt="Grid view" /></button></div>
					<div class="ItemThumbLayoutSwitch List"><button onClick="list();"><img src="/images/ListViewIcon.png" srcset="/images/ListViewIconRetina.png 2x" alt="Listed view" /></button></div>
				</div> -->
			</div>
		</div>
	<div class="Container">
	<div class="Box">
		<div class="Binder Big">

			<aside class="Aside">
				<button class="ItemMenuOpenButton" onClick="asidemenu()"><img src="images/FilterIcon.png" srcset="images/FilterIconRetina.png 2x" alt="Filtra" />Filtra</button>
				<nav class="ItemMenu">
					<button class="ItemMenuCloseButton" onClick="asidemenu()"><img src="images/NavCloseIcon.png" srcset="images/NavCloseIconRetina.png 2x" alt="Aside Menu" /></button>
					<ul>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'ALL'} }">Tutto</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'CAL'} }">Calze</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'FELP'} }">Felpe</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'GIAC'} }">Giacca-Giaccone-Gilet</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'GIUB'} }">Giubbino</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'KWAY'} }">K-Way</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'MAG'} }">Maglie</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'PACO'} }">Pantaloncino Corto</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'PALU'} }">Pantalone Lungo</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'PO'} }">POLO</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'TER'} }">Termico</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'TS'} }">T-Shirt</router-link></li>
						<li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { Categoria: 'TUTE'} }">Tute</router-link></li>           
					</ul>
				</nav>
			</aside>

			<div class="Content" v-if = "Products && Products.length > 0">
				<div class="Binder Big">
					<ProductCard
						v-for="Product in Products" 
						:key="Product.CodiceArticolo"
						:Prodotto="Product"
						:Template="'FeaturedProduct'
					"/>
				</div>
			</div>
			<div class="Content" v-if = "Products.length < 1">
				<div class="Binder Big">
					<h2>Non sono presenti articoli</h2>
				</div>
			</div>
		</div>	
	</div>
	</div>
	</section>
</div>
</template>

<script>
import ProductsService from "@/services/products.service.js";
import { modelMixin, risorseMixin} from "@/mixin.js";
import ProductCard from "@/components/ProductCard.vue";

export default {
    name: "CategoryView",
    data() {
        return {
            Products: [],
        };
    },
    methods: {
        getData: function () {
			let Cat = this.$route.params.Categoria;
			if ( Cat == "ALL" ) {
				Cat = "Abbigliamento";
			}
            let token = this.$store.getters.getToken;
            let shop = this.$store.getters.getShop;
            ProductsService.getArticoli(token, shop, Cat ).then(
                data => {
                    if (!data) {
                        this.$router.push("/");
                    } else {
                        this.Products = data;
                    }
                }
            );
        }
    },
    mixins: [modelMixin, risorseMixin],
	watch: { 
		'$route.params.Categoria': {
			handler: function() {
				this.getData();
			},
			deep: true,
			immediate: true
		}
	},
    created: function () {
        this.getData();
    },

    components: { ProductCard }
}
</script>
